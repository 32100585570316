export default function() {
  function initModule(module) {
    function getPage() {
      return parseInt(module.dataset.page, 10);
    }

    function setPage(page) {
      module.dataset.page = page;
    }

    function getTotalPages() {
      return parseInt(module.dataset.totalPages, 10);
    }

    function setTotalPages(totalPages) {
      module.dataset.totalPages = totalPages;
    }

    /* function getTotalCount() {
      return parseInt(module.dataset.totalCount, 10);
    } */

    function setTotalCount(totalCount) {
      module.dataset.totalCount = totalCount;
    }

    function setLoading(state) {
      if (state === true) {
        module.querySelector(`.tiles-view-loading`).classList.remove(`hidden`);
      } else {
        module.querySelector(`.tiles-view-loading`).classList.add(`hidden`);
      }
    }

    function getFilterSettings() {
      const folderCheckboxes = module.querySelectorAll(
        `.tiles-view-filter-folders input[type="checkbox"]:checked`
      );

      const folders = [];

      folderCheckboxes.forEach(checkbox => {
        folders.push(parseInt(checkbox.dataset.id, 10));
      });

      const tagCheckboxes = module.querySelectorAll(
        `.tiles-view-filter-tags input[type="checkbox"]:checked`
      );

      const tags = [];

      tagCheckboxes.forEach(checkbox => {
        tags.push(parseInt(checkbox.dataset.id, 10));
      });

      return {
        folders,
        tags
      };
    }

    function showLoadMore(state) {
      if (getPage() >= getTotalPages()) {
        state = false;
      }

      if (state === true) {
        module
          .querySelector(`.tiles-view-load-more`)
          .classList.remove(`hidden`);
      } else {
        module.querySelector(`.tiles-view-load-more`).classList.add(`hidden`);
      }
    }

    function showNoResults(state) {
      if (state === true) {
        module
          .querySelector(`.tiles-view-no-results`)
          .classList.remove(`hidden`);
      } else {
        module.querySelector(`.tiles-view-no-results`).classList.add(`hidden`);
      }
    }

    function clearResults() {
      module.querySelector(`.tiles-view-results`).innerHTML = ``;
    }

    function addResults(content) {
      module.querySelector(`.tiles-view-results`).innerHTML += content;
    }

    async function fetchTiles(page) {
      const filter = getFilterSettings();

      let url = `${module.dataset.url}?page=${page}`;

      if (filter.folders.length > 0) {
        const folderString = filter.folders.join(`,`);

        url += `&medien=${folderString}`;
      }

      if (filter.tags.length > 0) {
        const tagsString = filter.tags.join(`,`);

        url += `&tags=${tagsString}`;
      }

      if (module.dataset.folder) {
        url += `&folder=${module.dataset.folder}`;
      }

      if (module.dataset.pagesize) {
        url += `&pagesize=${module.dataset.pagesize}`;
      }

      const response = await window.fetch(url, {
        method: `GET`,
        headers: {
          "X-Requested-With": `XMLHttpRequest`
        }
      });

      return response.json();
    }

    async function loadTiles(page) {
      setLoading(true);

      fetchTiles(page).then(data => {
        setTotalPages(data.totalPages);
        setTotalCount(data.totalCount);
        setLoading(false);
        setPage(page);
        showLoadMore(true);

        if (data.totalCount === 0) {
          showNoResults(true);
        } else {
          showNoResults(false);
        }

        addResults(data.markup);
      });
    }

    function initEvents() {
      module
        .querySelector(`.tiles-view-load-more button`)
        .addEventListener(`click`, () => {
          showLoadMore(false);

          loadTiles(getPage() + 1);
        });

      module
        .querySelectorAll(
          `.tiles-view-filter-folders input[type="checkbox"], .tiles-view-filter-tags input[type="checkbox"]`
        )
        .forEach(item => {
          item.addEventListener(`click`, () => {
            clearResults();

            loadTiles(1);
          });
        });

      module
        .querySelectorAll(`.tiles-view-filter-folders input[type="checkbox"]`)
        .forEach(item => {
          item.addEventListener(`click`, () => {
            if (item.checked === true) {
              item.closest(`.folder`).classList.add(`checked`);
              item.closest(`.folder`).classList.remove(`unchecked`);
            } else {
              item.closest(`.folder`).classList.remove(`checked`);
              item.closest(`.folder`).classList.add(`unchecked`);
            }
          });
        });
    }

    showLoadMore(true);

    initEvents();
  }

  const modules = document.querySelectorAll(`.tiles-view`);

  if (!modules) {
    return;
  }

  modules.forEach(module => {
    initModule(module);
  });
}
