/* eslint-disable no-unused-vars */
import "whatwg-fetch";
import banner from "./modules/banner";
import form from "./modules/form";
import gallery from "./modules/gallery";
import wasserlaeuftTiles from "./modules/wasserlaeuft-tiles";
import wasserlaeuftGlossar from "./modules/wasserlaeuft-glossar";

const chunks = {
  banner,
  form,
  gallery,
  wasserlaeuftTiles,
  wasserlaeuftGlossar
};

Object.keys(chunks).map(chunk => {
  try {
    chunks[chunk]();
  } catch (error) {
    if (typeof chunks[chunk] !== `function`) {
      console.error(`${chunk} JS Module is not a function!`); // eslint-disable-line no-console
    } else {
      console.error(`Error in ${chunk}: ${error}`); // eslint-disable-line no-console
    }
  }
  return 1;
});
