import Mark from "mark.js/src/vanilla";

export default function() {
  function parseQueryString(query) {
    const vars = query.split(`&`);
    const queryString = {};

    for (let i = 0; i < vars.length; i += 1) {
      const pair = vars[i].split(`=`);
      const key = decodeURIComponent(pair[0]);
      const value = decodeURIComponent(pair[1]);

      if (typeof queryString[key] === `undefined`) {
        queryString[key] = decodeURIComponent(value);
      } else if (typeof queryString[key] === `string`) {
        queryString[key] = [queryString[key], decodeURIComponent(value)];
      } else {
        queryString[key].push(decodeURIComponent(value));
      }
    }

    return queryString;
  }

  function getOffset(el) {
    let _x = 0;
    let _y = 0;
    while (el && !Number.isNaN(el.offsetLeft) && !Number.isNaN(el.offsetTop)) {
      _x += el.offsetLeft - el.scrollLeft;
      _y += el.offsetTop - el.scrollTop;
      el = el.offsetParent;
    }
    return { top: _y, left: _x };
  }
  /*
  function scrollIt(destination, duration = 200, easing = `linear`, callback) {
    const easings = {
      linear(t) {
        return t;
      },
      easeInQuad(t) {
        return t * t;
      },
      easeOutQuad(t) {
        return t * (2 - t);
      },
      easeInOutQuad(t) {
        return t < 0.5 ? 2 * t * t : -1 + (4 - 2 * t) * t;
      },
      easeInCubic(t) {
        return t * t * t;
      },
      easeOutCubic(t) {
        t -= 1;
        return t * t * t + 1;
      },
      easeInOutCubic(t) {
        return t < 0.5
          ? 4 * t * t * t
          : (t - 1) * (2 * t - 2) * (2 * t - 2) + 1;
      },
      easeInQuart(t) {
        return t * t * t * t;
      }
    };

    const start = window.pageYOffset;
    const startTime =
      `now` in window.performance
        ? window.performance.now()
        : new Date().getTime();

    const documentHeight = Math.max(
      document.body.scrollHeight,
      document.body.offsetHeight,
      document.documentElement.clientHeight,
      document.documentElement.scrollHeight,
      document.documentElement.offsetHeight
    );
    const windowHeight =
      window.innerHeight ||
      document.documentElement.clientHeight ||
      document.getElementsByTagName(`body`)[0].clientHeight;
    const destinationOffset =
      typeof destination === `number` ? destination : destination.offsetTop;
    const destinationOffsetToScroll = Math.round(
      documentHeight - destinationOffset < windowHeight
        ? documentHeight - windowHeight
        : destinationOffset
    );

    if (`requestAnimationFrame` in window === false) {
      window.scroll(0, destinationOffsetToScroll);
      if (callback) {
        callback();
      }
      return;
    }

    function scroll() {
      const now =
        `now` in window.performance
          ? window.performance.now()
          : new Date().getTime();
      const time = Math.min(1, (now - startTime) / duration);
      const timeFunction = easings[easing](time);
      window.scroll(
        0,
        Math.ceil(timeFunction * (destinationOffsetToScroll - start) + start)
      );

      if (window.pageYOffset === destinationOffsetToScroll) {
        if (callback) {
          callback();
        }
        return;
      }

      window.requestAnimationFrame(scroll);
    }

    scroll();
  }
*/
  function openEntry(entryNode) {
    entryNode.classList.remove(`closed`);

    entryNode.querySelectorAll(`img`).forEach(image => {
      if (image.dataset.src !== ``) {
        image.src = image.dataset.src;
        image.dataset.src = ``;
      }
    });
  }

  function toggleEntry(entryNode) {
    if (entryNode.classList.contains(`closed`)) {
      openEntry(entryNode);
    } else {
      entryNode.classList.add(`closed`);
    }
  }

  const navigation = document.getElementById(`glossar-navigation`);

  document.querySelectorAll(`.glossar-search-module`).forEach(element => {
    const query = window.location.search.substring(1);
    const parameters = parseQueryString(query);
    const input = element.querySelector(`input[type="text"]`);

    element.querySelectorAll(`.glossar-entry`).forEach(entry => {
      entry.dataset.search = entry.innerHTML.toLowerCase();
    });

    function doSearch() {
      const keyword = input.value.trim().toLowerCase();

      let firstElement = null;

      element.querySelectorAll(`.glossar-entry`).forEach(entry => {
        if (!entry.classList.contains(`closed`)) {
          entry.classList.add(`closed`);
        }

        if (entry.dataset.search.indexOf(keyword) > -1) {
          openEntry(entry);

          const instance = new Mark(entry);
          instance.unmark();
          instance.mark(keyword);

          if (firstElement === null) {
            firstElement = entry;
          }
        }
      });

      /* scrollIt(
        firstElement.getBoundingClientRect().height -
          20 +
          parseInt(document.body.style.paddingTop, 10) -
          navigation.getBoundingClientRect().height,
        400,
        `easeInOutCubic`
      ); */
      window.scroll(
        0,
        firstElement.getBoundingClientRect().height -
          20 +
          parseInt(document.body.style.paddingTop, 10) -
          navigation.getBoundingClientRect().height
      );
    }

    element.querySelector(`form`).addEventListener(`submit`, e => {
      e.preventDefault();

      const value = input.value.replace(/[^a-zA-Z0-9äöüÄÖÜß ]/g, ``).trim();

      if (value === ``) {
        input.classList.add(`error`);
      } else {
        input.classList.remove(`error`);

        doSearch();
      }

      return false;
    });

    navigation.querySelectorAll(`a`).forEach(link => {
      link.addEventListener(`click`, e => {
        e.preventDefault();

        const section = document.getElementById(link.dataset.section);

        if (section) {
          let sectionTop = getOffset(section).top;

          sectionTop -= navigation.getBoundingClientRect().height + 20 + 90;

          // scrollIt(sectionTop, 400, `easeInOutCubic`);
          window.scroll(0, sectionTop);
        }
      });
    });

    // cancel all image loading activities for glossar entry lists
    document.addEventListener(`DOMContentLoaded`, () => {
      document.querySelectorAll(`.glossar-entries`).forEach(glossarEntry => {
        glossarEntry.querySelectorAll(`img`).forEach(image => {
          image.dataset.src = image.src;
          image.src = ``;
        });

        glossarEntry
          .querySelectorAll(`.glossar-entry-header`)
          .forEach(header => {
            header.addEventListener(`click`, e => {
              const entryNode = e.target.closest(`.glossar-entry`);

              toggleEntry(entryNode);
            });
          });
      });

      if (parameters.glossar_search) {
        input.value = parameters.glossar_search;

        doSearch();
      } else if (parameters.entry !== ``) {
        const entryNode = document.getElementById(`entry-${parameters.entry}`);

        if (entryNode) {
          openEntry(entryNode);

          let entryTop = getOffset(entryNode).top;

          entryTop -= navigation.getBoundingClientRect().height + 20 + 90;

          window.scroll(0, entryTop);
          // scrollIt(entryTop, 400, `easeInOutCubic`);
        }
      }
    });

    const navigationTop = getOffset(
      document.getElementById(`glossar-navigation`)
    ).top;

    function getScrollPosition() {
      if (window.pageYOffset >= navigationTop - 90 - 15) {
        navigation.classList.add(`sticky`);

        if (navigation) {
          const navigationRect = navigation.getBoundingClientRect();
          const height = navigationRect.height + 15 + 90;
          document.body.style.paddingTop = `${height}px`;
        }
      } else {
        navigation.classList.remove(`sticky`);

        document.body.style.paddingTop = `90px`;
      }
    }

    window.onscroll = getScrollPosition;
  });

  document.querySelectorAll(`.glossar-search-teaser`).forEach(element => {
    element.querySelector(`form`).addEventListener(`submit`, e => {
      e.preventDefault();

      const input = element.querySelector(`input[type="text"]`);
      const value = input.value.replace(/[^a-zA-Z0-9äöüÄÖÜß ]/g, ``).trim();

      input.value = value;

      if (value === ``) {
        input.classList.add(`error`);
      } else {
        input.classList.remove(`error`);

        window.location.assign(
          `${element.dataset.glossarUrl}?glossar_search=${encodeURIComponent(
            input.value
          )}`
        );
      }

      return false;
    });
  });
}
